import React, { useState } from 'react';
import Resultado from './Resultado';

function CalculadoraCalorica() {
  const [peso, setPeso] = useState('');
  const [altura, setAltura] = useState('');
  const [edad, setEdad] = useState('');
  const [genero, setGenero] = useState('hombre');
  const [nivelActividad, setNivelActividad] = useState('sedentario');
  const [deficit, setDeficit] = useState('');
  const [deficitCalorico, setDeficitCalorico] = useState(null);
  const [errores, setErrores] = useState({});

  const handleCalculate = (e) => {
    e.preventDefault();

    const erroresTemp = {};

    if (isNaN(peso) || peso <= 0 || peso.length > 5) {
      erroresTemp.peso = 'Por favor, ingrese un valor válido para el peso (por ejemplo, 81.1).';
    }

    if (isNaN(altura) || altura <= 0 || altura.length !== 3) {
      erroresTemp.altura = 'Por favor, ingrese un valor válido para la altura (3 dígitos, ej. 170).';
    }

    if (isNaN(edad) || edad <= 0 || edad.length > 3) {
      erroresTemp.edad = 'Por favor, ingrese un valor válido para la edad.';
    }

    const deficitValor = parseFloat(deficit);
    if (isNaN(deficitValor) || deficitValor <= 0) {
      erroresTemp.deficit = 'Por favor, ingrese un valor válido para el déficit calórico.';
    }

    if (Object.keys(erroresTemp).length > 0) {
      setErrores(erroresTemp);
      return;
    }

    setErrores({});

    let TMB;
    if (genero === 'hombre') {
      TMB = 88.362 + 13.397 * peso + 4.799 * altura - 5.677 * edad;
    } else {
      TMB = 447.593 + 9.247 * peso + 3.098 * altura - 4.330 * edad;
    }

    const factoresActividad = {
      sedentario: 1.2,
      ligera_actividad: 1.375,
      moderada_actividad: 1.55,
      muy_activo: 1.725,
      extremadamente_activo: 1.9,
    };

    const caloriasMantenimiento = TMB * factoresActividad[nivelActividad];
    const caloriasDeficit = caloriasMantenimiento - deficitValor;

    setDeficitCalorico(caloriasDeficit);
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-lg p-4">
        <h2 className="text-center text-primary mb-4">Calculadora de Déficit Calórico</h2>
        <form onSubmit={handleCalculate} className="form-group">
          <div className="mb-3">
            <label className="form-label">Peso (kg):</label>
            <input
              type="number"
              className="form-control"
              value={peso}
              onChange={(e) => setPeso(e.target.value)}
              required
            />
            {errores.peso && <div className="text-danger">{errores.peso}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Altura (cm):</label>
            <input
              type="number"
              className="form-control"
              value={altura}
              onChange={(e) => setAltura(e.target.value)}
              maxLength={3}
              required
            />
            {errores.altura && <div className="text-danger">{errores.altura}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Edad:</label>
            <input
              type="number"
              className="form-control"
              value={edad}
              maxLength={3}
              min={5}
              max={150}
              onChange={(e) => setEdad(e.target.value)}
              required
            />
            {errores.edad && <div className="text-danger">{errores.edad}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Género:</label>
            <select
              className="form-select"
              value={genero}
              onChange={(e) => setGenero(e.target.value)}
            >
              <option value="hombre">Hombre</option>
              <option value="mujer">Mujer</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Nivel de Actividad:</label>
            <select
              className="form-select"
              value={nivelActividad}
              onChange={(e) => setNivelActividad(e.target.value)}
            >
              <option value="sedentario">Sedentario</option>
              <option value="ligera_actividad">Ligera Actividad</option>
              <option value="moderada_actividad">Actividad Moderada</option>
              <option value="muy_activo">Muy Activo</option>
              <option value="extremadamente_activo">Extremadamente Activo</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Déficit Calórico Deseado (kcal/día):</label>
            <input
              type="number"
              className="form-control"
              value={deficit}
              onChange={(e) => setDeficit(e.target.value)}
              required
            />
            {errores.deficit && <div className="text-danger">{errores.deficit}</div>}
          </div>
          <button type="submit" className="btn btn-primary w-100">Calcular Déficit Calórico</button>
        </form>
        {deficitCalorico !== null && <Resultado deficitCalorico={deficitCalorico} />}
      </div>
    </div>
  );
}

export default CalculadoraCalorica;
