import React from 'react';

function Resultado({ deficitCalorico }) {
  return (
    <div className="mt-4 p-4 bg-light rounded shadow-sm">
      <h2 className="text-center text-primary mb-4">Resultado</h2>
      {deficitCalorico !== null && deficitCalorico !== undefined ? (
        <p className="lead text-center text-success">
          Para alcanzar tu objetivo con el déficit calórico especificado, debes consumir
          <strong> {deficitCalorico.toFixed(2)} kcal</strong> por día.
        </p>
      ) : (
        <p className="text-center text-muted">
          Introduce los datos y calcula para ver el déficit calórico.
        </p>
      )}
    </div>
  );
}

export default Resultado;
