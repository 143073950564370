import React from 'react';
import CalculadoraCalorica from './components/Calculadora';
import Footer from './components/Footer';  // Import the Footer component
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="container mt-5">
      {/* <h1 className="text-center mb-4">Calculadora de Déficit Calórico</h1> */}
      <CalculadoraCalorica />
      <Footer /> {/* Include the Footer component */}
    </div>
  );
}

export default App;
